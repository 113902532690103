import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { HTMLContent } from '../components/Content';
import BackgroundImage from '../components/BackgroundImage';
import { ImageOverlay } from '../utils';
import './blog.css';
import useSiteMetadata from '../components/SiteMetadata';
import MetaComponent from '../components/MetaComponent';

export const BlogPostTemplate = ({
  content,
  description,
  date,
  title,
  helmet,
  heroImageObject,
}) => {
  return (
    <section className='blog-post-container'>
      <BackgroundImage
        classes={'page-header-image'}
        imageInfo={heroImageObject.image}>
        <h1 className='title page-title'>
          <hr />
          {title}
        </h1>
        <ImageOverlay overlay={heroImageObject.overlay} />
      </BackgroundImage>
      {helmet || ''}
      <div className='page-container container content'>
        <div className='columns'>
          <div className='column is-10 is-offset-1'>
            <HTMLContent content={content} className='blog-content' />
          </div>
        </div>
      </div>
    </section>
  );
};

const BlogPost = ({ data, location }) => {
  const { markdownRemark: post } = data;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <BlogPostTemplate
        location={location}
        content={post.html}
        date={post.frontmatter.date}
        heroImageObject={post.frontmatter.heroImageObject}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <MetaComponent
            title={`${post.frontmatter.title}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${post.frontmatter.description}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${post.frontmatter.heroImageObject.image}`}
          />
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        heroImageObject {
          image
          overlay
        }
        title
        description
        tags
      }
    }
  }
`;
